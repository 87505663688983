import React, { useEffect } from 'react';
import { fetchAutomotorTariffInfo, fetchAutomotorTariffs } from 'store/reducers/automotor-tariff/automotor-tariff.actions';
import { getAutomotorTariff, getTariffs } from 'store/selectors/automotor-tariff.selector';
import { useAppDispatch } from 'utils/hooks/redux';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EspaceAutomotorNavbar from './espace-automotor-navbar/EspaceAutomotorNavbar';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import AutomotorMenuNavbar from './automotor-menu-navbar/AutomotorMenuNavbar';
import EspaceReliableNavbarMobile from 'applications/automotor/components/espace/navbar/espace-reliable-navbar/Mobile/EspaceReliableNavbarMobile';
import './EspaceAutomotorLayout.scss';
import UploadProformaAutomotor from 'applications/automotor/views/automotor-home-page/UploadProformaAutomotorModal/UploadProformaAutomotor';
import AutomotorNavbarGroupBtn from 'applications/automotor/views/automotor-home-page/AutomotorNavbarGroupBtn/AutomotorNavbarGroupBtn';
import CheckReferenceDisponibility from 'applications/automotor/components/espace-automotor/check-reference-disponibility/CheckReferenceDisponibility';
import ClientInfoFinancial from 'applications/automotor/views/espace-automotor-financial/ClientInfo/ClientInfoFinancial';

const c = 'automotor-menu-navbar';

const AutomotorLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const automotorTariff = useSelector(getAutomotorTariff);
  const tariffs = useSelector(getTariffs);
  const { isMobile, isTablet } = useDeviceDetect();

  useEffect(() => {
    if (!automotorTariff) {
      dispatch(fetchAutomotorTariffInfo());
    }

    if (!tariffs) {
      dispatch(fetchAutomotorTariffs());
    }
  }, [automotorTariff, tariffs]);

  return (
    <div className={`espace-automotor-container-wrapper ${location.pathname.includes('/automotor-online') ? 'aol-layout' : ''}`}>
      <div className="container">
        <div className="header">
          {isMobile || isTablet ? <EspaceReliableNavbarMobile /> : <EspaceAutomotorNavbar />}
          {isMobile || isTablet ? null : <AutomotorMenuNavbar />}

          {location.pathname.includes('/automotor-online') ?
            <div className={`${c}__info`}>
              <div className=" navbar-col-child">
                <ClientInfoFinancial />
              </div>
              <CheckReferenceDisponibility />
              <div className="navbar-col-child"></div>
              <div className={`${c}__info__catalog-btn floating-btn-group`}>
                <AutomotorNavbarGroupBtn />
              </div>
            </div>
            : null}
          <UploadProformaAutomotor />
        </div>
        <Outlet />
        {/* <div className="footer">
          {isMobile ? (
            <div className="reference-estimation">
              <CheckReferenceDisponibility />
              <SubmitEstimation />
            </div>
          ) : (
            <div className="reference-estimation">
              <SubmitEstimation />
              <CheckReferenceDisponibility />
            </div>
          )}
          <Footer />
        </div> */}
      </div>
    </div>
  );
};

export default AutomotorLayout;
